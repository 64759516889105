import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The enumerate built-in function it's a great way to iterate over something. Your code will be more readable and you have some flexibility when building a loop. You can use enumerate in any sequence, iterator or another object that supports iteration.`}</p>
    <p>{`Instead of writing a loop like this `}<inlineCode parentName="p">{`for i in range(len(foo))`}</inlineCode>{` to get the index of `}<inlineCode parentName="p">{`foo`}</inlineCode>{` a better way would be to use the enumerate, because this function returns the index and value of `}<inlineCode parentName="p">{`foo`}</inlineCode>{`. `}</p>
    <h1 {...{
      "id": "using-enumerate-in-a-loop",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-enumerate-in-a-loop",
        "aria-label": "using enumerate in a loop permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using Enumerate in a Loop`}</h1>
    <p>{`Using enumerate in a loop is quite easy. You can either pass one or two variables to the for loop and then wrap the thing you want to loop over with the `}<inlineCode parentName="p">{`enumerate()`}</inlineCode>{` function.`}</p>
    <p>{`The difference between passing one or two variables is the result returned to you. If you pass one variable, you'll get a tuple. If you pass two variables, you'll get an `}<inlineCode parentName="p">{`int`}</inlineCode>{` containing the index number and the value.`}</p>
    <p>{`So a loop can be written with a single variable like this:
`}<inlineCode parentName="p">{`for i in enumerate(foo)`}</inlineCode></p>
    <p>{`You would get the following tuple:
`}<inlineCode parentName="p">{`(1, 'bar')`}</inlineCode></p>
    <p>{`Or you can pass two variables into the loop like this:
`}<inlineCode parentName="p">{`for index, value in enumerate(foo)`}</inlineCode></p>
    <p>{`You will get the following two values:
`}<inlineCode parentName="p">{`1 'bar'`}</inlineCode></p>
    <h1 {...{
      "id": "basic-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#basic-example",
        "aria-label": "basic example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic Example`}</h1>
    <p>{`Now that you know, what you will get, if you pass one or two variables to a for loop, let's see an example where `}<inlineCode parentName="p">{`enumerate()`}</inlineCode>{` is used.`}</p>
    <p>{`Let's imagine, you created a list containing the names of your neighbours. The list looks like this:`}</p>
    <p><inlineCode parentName="p">{`names = ["John", "Leah", "Bob", "Thomas", "John", "Bob", "Bob"]`}</inlineCode></p>
    <p>{`Next, you want to know the index of every name, so you can do something with it.  If you pass only one variable to the loop you will get this tuple:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`for name in enumerate(names):
    print(name)

(0, 'John')
(1, 'Leah')
(2, 'Bob')
(3, 'Thomas')
(4, 'John')
(5, 'Bob')
(6, 'Bob')
`}</code></pre>
    <p>{`But if you use two variables you will get the following result:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`for index, name in enumerate(names):
    print(index, name)

0 John
1 Leah
2 Bob
3 Thomas
4 John
5 Bob
6 Bob
`}</code></pre>
    <p>{`As you can see, you could use two variables, if you want more flexibility and make your code more readable.`}</p>
    <h1 {...{
      "id": "example-hamming-difference-between-two-dna-strands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#example-hamming-difference-between-two-dna-strands",
        "aria-label": "example hamming difference between two dna strands permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example: Hamming difference between two DNA strands`}</h1>
    <p><em parentName="p">{`Disclaimer: This part contains my solution of the hamming problem found on exercism.io`}</em></p>
    <p>{`This example is taken from one of the many exercises, that you can get from `}<a parentName="p" {...{
        "href": "http://exercism.io/"
      }}>{`exercism.io`}</a>{`. I recommend you to check it, as the challenges are pretty awesome and fun to complete!`}</p>
    <p>{`The Hamming distance is a measure of similarity between two strings of equal length and it measures the minimum number of substitutions required to change one string into the other. `}</p>
    <p>{`In the exercism challenge we have to calculate the Hamming difference between two DNA strands.`}</p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-git"
      }}>{`GAGCCTACTAACGGGAT
CATCGTAATGACGGCCT
^ ^ ^  ^ ^    ^^

The Hamming distance between these two DNA strands is 7.
`}</code></pre>
    <p>{`This is a perfect exercise for us to use our knowledge of the enumerate built-in function and solve this problem. `}</p>
    <p>{`Exercism.io provides us with a few test cases to check if our solution is adequate. Some of the test cases look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def test_large_distance(self):
    self.assertEqual(hamming.distance("GATACA", "GCATAA"), 4)

def test_large_distance_in_off_by_one_strand(self):
    self.assertEqual(hamming.distance("GGACGGATTCTG", "AGGACGGATTCT"), 9)

def test_empty_strands(self):
    self.assertEqual(hamming.distance("", ""), 0)

def test_disallow_first_strand_longer(self):
    with self.assertRaises(ValueError):
        hamming.distance("AATG", "AAA")
`}</code></pre>
    <h2 {...{
      "id": "solving-the-problem",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#solving-the-problem",
        "aria-label": "solving the problem permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Solving the problem`}</h2>
    <p>{`To solve this issue we will need:`}</p>
    <ul>
      <li parentName="ul">{`A place to add up all the different occurrences between two strings`}</li>
      <li parentName="ul">{`A test to see if the two strings are the same length`}</li>
      <li parentName="ul">{`A loop to test the two indexes together`}</li>
    </ul>
    <p>{`Now that we have all the tools needed, building the function is pretty easy. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`1   def distance(first_strand, second_strand):
2       total = 0
3       if len(first_strand) != len(second_strand):
4           raise ValueError
5       for index, value in enumerate(first_strand):
6           if first_strand[index] != second_strand[index]:
7               total += 1
8       return total
`}</code></pre>
    <p>{`(line 3) Due to the hamming constraint, we first check if both strings are the same length.`}</p>
    <p>{`(line 5) I've opted to use two variables in the for loop, because it makes the code more readable. Since all we need is the index number, we could just use the first value of the tuple.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      